/* eslint-disable max-len */
import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import RecipeIcon from '@mui/icons-material/AssignmentOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import TuneIcon from '@mui/icons-material/Tune';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CattleIcon from '../../assets/HoofIcon';
import { fromOADate } from '../../helpers';
import { useSettings } from '../../hooks';
import { DatePicker, SearchInput } from '../shared';
import Autocomplete from '../shared/autocomplete';
import Tree from '../shared/tree';

const ComparisonsTree = (props) => {
  const { pickedFarms, handleClick, mobileView, currentComparisons, onClear, farmsList = [], onFarmsSelect } = props;

  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState(subYears(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const [selectedLocations, setSelectedLocations] = useState([]);

  const filteredFarms = useMemo(() => {
    let result = pickedFarms.length > 0 ? [...pickedFarms] : [...farmsList];
    if (search) {
      result = result.filter((farm) => !search || farm.farm_name?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    return result;
  }, [pickedFarms, search]);

  const locationsList = useMemo(() => {
    let result = [];

    if (filteredFarms?.length) {
      filteredFarms?.forEach((farm) => {
        farm?.locations?.forEach((location) => {
          if (!result?.find((l) => l.location_name === location.location_name)) {
            result?.push(location);
          }
        });
      });
    }

    return result;
  }, [filteredFarms, search]);

  const treeData = useMemo(
    () =>
      filteredFarms
        ?.filter((farm) => {
          return (
            !selectedLocations?.length ||
            farm?.locations?.some((location) =>
              selectedLocations?.some((l) => location.location_name === l.location_name)
            )
          );
        })
        .map((farm) => ({
          id: farm.farm_id,
          label: (
            <Box component='div'>
              <FarmIcon fontSize='small' /> {farm.farm_name}
            </Box>
          ),
          children: farm.locations
            ?.filter((location) => {
              return (
                (!selectedLocations?.length ||
                  selectedLocations?.find((l) => l.location_name === location.location_name)) &&
                location?.cattles?.some((cattle) =>
                  cattle?.implementations?.some((impl) => {
                    const date = fromOADate(impl.implemented_oadate);
                    return isAfter(date, startOfDay(startDate)) && isBefore(date, endOfDay(endDate));
                  })
                )
              );
            })
            .map((loc) => ({
              id: `${farm.farm_id}${loc.location_name}`,
              label: (
                <Box component='div'>
                  <LocationIcon fontSize='small' /> {loc.location_name}
                </Box>
              ),
              children: loc.cattles.map((cattle, index) => ({
                id: `${farm.farm_id}${loc.location_name}${cattle.cattle_name}_${index}`,
                label: (
                  <Box component='div'>
                    <CattleIcon fontSize='small' /> {cattle.cattle_name}
                  </Box>
                ),
                children: cattle.implementations
                  .filter((impl) => {
                    const date = fromOADate(impl.implemented_oadate);
                    return isAfter(date, startOfDay(startDate)) && isBefore(date, endOfDay(endDate));
                  })
                  .map((rec) => {
                    const id = `${farm.farm_id}${loc.location_name}${cattle.cattle_name}${rec.implemented_oadate}${rec.recipe}`;
                    return {
                      id,
                      selected: !!currentComparisons?.find((item) => item.id === id),
                      label: (
                        <Box component='div'>
                          <RecipeIcon fontSize='small' />
                          <span style={{ display: 'flex' }}>
                            {rec.recipe} ({formatDate(rec.implemented_oadate, true, true)}){' '}
                            {rec.type === 'Proposed' && '*'}
                          </span>
                        </Box>
                      ),
                      onClick: () =>
                        handleClick({
                          ...rec,
                          id,
                          farmName: farm.farm_name,
                          farmId: farm.farm_id,
                          locName: loc.location_name,
                          cattleName: cattle.cattle_name,
                        }),
                    };
                  }),
              })),
            })),
        })),
    [filteredFarms, selectedLocations, startDate, endDate]
  );

  const onChange = async (e, farms) => {
    onFarmsSelect(farms);
    setSelectedLocations([]);
  };

  const onLocationsChange = async (e, locations) => {
    setSelectedLocations(locations);
  };

  return (
    <Box component='div' sx={{ p: { md: 2, sm: 0, xs: 0 } }}>
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('comparisons.selectRations')}
        </Typography>
        {currentComparisons && !!currentComparisons?.length && (
          <Box component='div' sx={{ mt: 1, ml: 1 }}>
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <MuiAlert icon={<HelpOutlineIcon />} severity='info' sx={{ mt: 2 }} variant='outlined'>
        {t('rations.proposalInfo')}
      </MuiAlert>

      <Box
        sx={{
          mt: 2,
          p: 1,
          background: '#ffffff',
          borderRadius: 1,
          border: (theme) => `1px solid ${theme?.palette?.primary?.grey}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TuneIcon color='secondary' />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Box>
          <Box sx={{ flex: 1, ml: 2, mr: 2 }}>
            <SearchInput
              handleClear={() => setSearch('')}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Search by farm name'
              size='small'
              sx={{ width: '100%' }}
              value={search}
            />
          </Box>
          <IconButton onClick={() => setOpen(!open)} size='small'>
            {open ? <ArrowUp /> : <ArrowDown />}
          </IconButton>
        </Box>
        {open && (
          <>
            <Box sx={{ mt: 2, maxWidth: 600 }}>
              <Autocomplete
                getOptionLabel={(option) => option.farm_name}
                inputLabel={t('farms.label')}
                inputPlaceholder={t('farms.placeholder')}
                isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
                labelKey='farm_name'
                mb={0}
                noOptionsText={t('farms.noOptions')}
                onChange={onChange}
                options={farmsList}
                size='small'
                value={pickedFarms}
                valueKey='farm_id'
              />
            </Box>
            <Box sx={{ mt: 2, maxWidth: 600 }}>
              <Autocomplete
                getOptionLabel={(option) => option.location_name}
                inputLabel='Locations'
                inputPlaceholder='Select locations'
                isOptionEqualToValue={(option, value) => option.location_name === value.location_name}
                labelKey='location_name'
                mb={0}
                noOptionsText='No Options'
                onChange={onLocationsChange}
                options={locationsList}
                size='small'
                value={selectedLocations}
                valueKey='location_name'
              />
            </Box>
            <Box sx={{ mt: 2, display: 'flex' }}>
              <Box component='div' sx={{ mr: 1, flex: 1 }}>
                <DatePicker label='From' maxDate={endDate} onChange={setStartDate} size='small' value={startDate} />
              </Box>
              <Box component='div' sx={{ ml: 1, flex: 1 }}>
                <DatePicker
                  disableFuture
                  label='To'
                  minDate={startDate}
                  onChange={setEndDate}
                  size='small'
                  value={endDate}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default ComparisonsTree;
