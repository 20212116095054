import FarmVisitIcon from '@mui/icons-material/DirectionsWalk';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import TuneIcon from '@mui/icons-material/Tune';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from '../shared';
import Autocomplete from '../shared/autocomplete';
import Tree from '../shared/tree';

const FarmVisitTree = (props) => {
  const { results = [], onClick, mobileView, selected, onClear, farmsList, onFarmsSelect, pickedFarms } = props;

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const filteredResults = useMemo(() => {
    if (results?.length) {
      if (search.length) {
        return results.filter((el) => el.Title?.toLowerCase().includes(search.toLowerCase()));
      }
      return results;
    }
    return results;
  }, [search, results]);

  const treeData = useMemo(
    () =>
      filteredResults.map((farm) => ({
        id: farm.id,
        label: (
          <Box component='div'>
            <FarmVisitIcon fontSize='small' /> {farm.Title}
          </Box>
        ),
        selected: selected === farm.id,
        onClick: () => onClick(farm),
      })),
    [filteredResults, selected]
  );

  const onChange = async (e, farm) => {
    onFarmsSelect(farm);
  };

  return (
    <Box
      component='div'
      sx={{
        p: {
          md: 2,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('adminFarmVisit.selectResult')}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            mt: 2,
            p: 1,
            background: '#ffffff',
            borderRadius: 1,
            border: (theme) => `1px solid ${theme?.palette?.primary?.grey}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TuneIcon color='secondary' />
              <Typography sx={{ ml: 1 }}>Filters</Typography>
            </Box>
            <Box sx={{ flex: 1, ml: 2, mr: 2 }}>
              <Autocomplete
                getOptionLabel={(option) => option.farm_name}
                inputLabel={t('farms.label')}
                inputPlaceholder={t('farms.placeholder')}
                isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
                labelKey='farm_name'
                mb={0}
                multiple={false}
                noOptionsText={t('farms.noOptions')}
                onChange={onChange}
                options={farmsList}
                size='small'
                value={pickedFarms}
                valueKey='farm_id'
              />
            </Box>
            <IconButton onClick={() => setOpen(!open)} size='small'>
              {open ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
          </Box>
          {open && (
            <>
              <Box sx={{ mt: 2, maxWidth: 600 }}>
                <SearchInput
                  handleClear={() => setSearch('')}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='Search by farm name'
                  size='small'
                  sx={{ width: '100%' }}
                  value={search}
                />
              </Box>
            </>
          )}
        </Box>
        {!!selected && (
          <Box
            component='div'
            sx={{
              mt: 1,
              ml: 1,
            }}
          >
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
      {!pickedFarms && <Alert severity='info'>{t('adminFarmVisit.pickFarm')}</Alert>}
    </Box>
  );
};

export default FarmVisitTree;
